import "./circle.css";

function Circle({ employees, index }) {
  return (
    <li
      className="circle"
      style={{
        zIndex: `${20 - index}`,
        width: `${(index + 1) * 6 + 9}em`,
        height: `${(index + 1) * 6 + 9}em`,
        animationDuration: `${(index + 1) * 50}s`,
      }}
      key={index}
    >
      {employees.map((employee) => (
        <div
          key={employee._id}
          style={{
            animationDuration: `${(index + 1) * 50}s`,
          }}
          className={`employee ${employee.sleep ? "sleep" : ""}`}
        >
          <img src={employee.img} alt={employee.name} />
          <span>{employee.name}</span>
        </div>
      ))}
    </li>
  );
}

export default Circle;
