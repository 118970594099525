import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import moment from "moment-timezone";

import EmployeesList from "../components/EmployeesList/EmployeesList";
import EmployeeCard from "../components/EmployeeCard/EmployeeCard";

moment.tz.setDefault("Europe/Sarajevo");

function MainBorard({
  employees,
  updateEmployees,
  garages,
  updateGarages,
  guests,
  updateGuests,
}) {
  const [employeesBoard, setEmployeesBoard] = useState([]);
  const [inputCode, setInputCode] = useState("");
  const inputRef = useRef(null);
  const [guestPopup, setGuestPopup] = useState(false);

  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedMode = localStorage.getItem("darkMode");
    return savedMode ? JSON.parse(savedMode) : false;
  });
  const [selectedInterval, setSelectedInterval] = useState("week");

  useEffect(() => {
    localStorage.setItem("darkMode", JSON.stringify(isDarkMode));
  }, [isDarkMode]);

  const clockOutAllPresentEmployees = async () => {
    const currentTime = moment(new Date()).tz("Europe/Sarajevo");
    if (currentTime.hours() === 23 && currentTime.minutes() === 59) {
      await axios.put("/api/employees/clock-out-all-present-employees");
      updateEmployees();
    }
  };

  useEffect(() => {
    sortEmployeesByInterval(selectedInterval);
  }, [employees, selectedInterval]);

  useEffect(() => {
    const handleClick = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        inputRef.current.focus();
      }
    };

    // Add the event listener when the component mounts
    document.getElementById("main").addEventListener("click", handleClick);

    return () => {
      document.getElementById("main").removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    // Set up a timer to check for midnight every minute
    const midnightCheckTimer = setInterval(() => {
      clockOutAllPresentEmployees();
    }, 40000);

    return () => {
      clearInterval(midnightCheckTimer);
    };
  }, []);

  const handleCodeChange = (event) => {
    setInputCode(event.target.value);
  };

  const togglePresence = async (employeeIdOrCode) => {
    try {
      let employeeToUpdate;

      if (employeeIdOrCode) {
        employeeToUpdate = employees.find(
          (employee) => employee._id === employeeIdOrCode
        );

        const id = employeeToUpdate._id;
        await axios.put(`/api/employees/${id}/toggle-remote`);
        updateEmployees();
      } else {
        await axios.get("/api/photo/capture");
        employeeToUpdate = employees.find(
          (employee) => employee.code === inputCode
        );
      }

      if (!employeeToUpdate) {
        console.log("Employee not found.");
        return;
      }

      await axios.put(
        `/api/employees/${employeeToUpdate._id}/action/${
          !employeeToUpdate.present ? "clock-in" : "clock-out"
        }`
      );

      setInputCode("");
      inputRef.current.focus();

      updateEmployees();
    } catch (error) {
      console.error("Error updating presence:", error);
    }
  };

  const toggleSleep = async (id) => {
    await axios.put(`/api/employees/${id}/toggle-sleep`);
    updateEmployees();
  };

  function sortEmployeesByInterval(interval) {
    if (interval === "day") {
      const sortedEmployees = [...employees].sort((a, b) => {
        const aTime =
          a.workHistory && a.workHistory[0] && a.workHistory[0].totalTimeInDay
            ? a.workHistory[0].totalTimeInDay
            : 0;
        const bTime =
          b.workHistory && b.workHistory[0] && b.workHistory[0].totalTimeInDay
            ? b.workHistory[0].totalTimeInDay
            : 0;
        return bTime - aTime;
      });
      setEmployeesBoard(sortedEmployees);
      return;
    }

    const sortedEmployees = [...employees].sort(
      (a, b) => b[interval] - a[interval]
    );
    setEmployeesBoard(sortedEmployees);
  }

  const handleFormSubmit = (event) => {
    event.preventDefault();
    togglePresence();
  };

  const intervals = ["day", "week", "month", "year"];

  async function handleGarageOccupied(id) {
    await axios.put(`/api/garages/${id}/toggle-occupy`);
    updateGarages();
  }

  async function addNPC(gender) {
    await axios.post(`/api/guests`, { gender: gender });
    updateGuests();
    setGuestPopup(false);
  }

  async function removeNPC(id) {
    await axios.delete(`/api/guests/${id}`);
    updateGuests();
  }

  return (
    <div className={`grid ${isDarkMode ? "dark-mode" : ""}`}>
      <div className="sidebar">
        <div className="header">
          <h2>Leader Board in a</h2>
          <select
            id="intervalSelect"
            value={selectedInterval}
            onChange={(e) => setSelectedInterval(e.target.value)}
          >
            {intervals.map((interval) => (
              <option key={interval} value={interval}>
                {interval.charAt(0).toUpperCase() + interval.slice(1)}
              </option>
            ))}
          </select>
        </div>
        {employeesBoard.map((em, i) => {
          let intervalTime = 0;
          switch (selectedInterval) {
            case "day":
              intervalTime = em.workHistory[0].totalTimeInDay || 0;
              break;
            case "week":
              intervalTime = em.week || 0;
              break;
            case "month":
              intervalTime = em.month || 0;
              break;
            case "year":
              intervalTime = em.year || 0;
              break;
            default:
              intervalTime = 0;
          }
          return (
            <EmployeeCard
              togglePresence={() => {
                togglePresence(em._id);
              }}
              toggleSleep={() => {
                toggleSleep(em._id);
              }}
              key={i}
              index={i}
              employee={em}
              totalTime={intervalTime}
            />
          );
        })}
      </div>
      <main id="main">
        <form className="presence-form" onSubmit={handleFormSubmit}>
          <input
            type="text"
            value={inputCode}
            onChange={handleCodeChange}
            ref={inputRef}
          />
        </form>
        <EmployeesList list={employees} />
        <DarkModeSwitch
          className="darkmode-btn"
          checked={isDarkMode}
          onChange={() => {
            setIsDarkMode((prevMode) => !prevMode);
          }}
          size={30}
          sunColor={"rgba(var(--secondary-rgb), .5)"}
        />
        <footer>
          <div className="guests">
            {guestPopup && (
              <div className="popup">
                <div onClick={() => addNPC("male")} className="avatar">
                  <img src="media/avatar-male.png" alt="avatar"></img>
                </div>
                <div onClick={() => addNPC("female")} className="avatar">
                  <img src="media/avatar-female.png" alt="avatar"></img>
                </div>
              </div>
            )}
            <div
              onClick={() => setGuestPopup((g) => !g)}
              className="add-avatar"
            >
              <span>+</span>
            </div>
            {guests.map((guest) => (
              <div
                key={guest._id}
                onClick={() => removeNPC(guest._id)}
                className={`avatar ${guest.present ? "present" : ""}`}
              >
                <img
                  src={`media/avatar-${guest.gender.toLowerCase()}.png`}
                  alt="avatar"
                ></img>
              </div>
            ))}
          </div>
          <div className="garages-status">
            {garages.map((garage) => (
              <div
                key={garage._id}
                onClick={() => handleGarageOccupied(garage._id)}
                className={`garage ${garage.occupied ? "occupied" : ""}`}
              >
                <img src="media/garage-ico.svg" alt="garage-space"></img>
              </div>
            ))}
          </div>
        </footer>
      </main>
    </div>
  );
}

export default MainBorard;
