import { useState } from "react";
import { Outlet } from "react-router-dom";

import djeSiMacko from "./../../assets/audio/djeSiMacko.mp3";
import heheMajmuncina from "./../../assets/audio/heheMajmuncina.mp3";
import majumunee from "./../../assets/audio/majmunee.mp3";

import "./protectedRoute.css";

function AuthForm({ onAuthenticate }) {
  const [password, setPassword] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    onAuthenticate(password);
    setPassword("");
  };

  return (
    <div className="auth-form-container">
      <form className="auth-form" onSubmit={handleSubmit}>
        <div className="company">
          <img className="logo-min" src="/logo.svg" alt="logo" />
        </div>
        <h2>Say the magic word!</h2>
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)} // Update local state on change
        />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

function ProtectedRoute() {
  const [authenticated, setAuthenticated] = useState(false);
  const audio = new Audio();
  const handleAuthenticate = (password) => {
    if (password === "paprikas" || password === "majmuneeeee") {
      setAuthenticated(true);
    } else {
      audio.src = [djeSiMacko, majumunee, heheMajmuncina][
        Math.floor(Math.random() * 3)
      ];
      audio.play();
      setAuthenticated(false);
    }
  };

  if (!authenticated) {
    return <AuthForm onAuthenticate={handleAuthenticate} />;
  }

  return <Outlet />;
}

export default ProtectedRoute;
