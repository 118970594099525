// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth-form-container {
  height: 100vh;
  display: flex;
}

.auth-form {
  margin: auto;
  padding: 20px;
  /* background: #f1f1f1; */
  border-radius: 10px;
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.auth-form .company {
  --size: 130px;
  margin: 0 auto;
}

.auth-form input {
  padding: 6px 20px;
  width: 100%;
  padding: 7px 20px;
  border-radius: 6px;
  border: 2px solid #111;
}

.auth-form button {
  display: block;
  width: 100%;
  margin-top: 20px;
  border: none;
  background: #111;
  color: #fff;
  font-weight: 700;
  padding: 7px 20px;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s;
}

.auth-form button:hover {
  background: #333;
}
`, "",{"version":3,"sources":["webpack://./src/layout/ProtectedRoute/protectedRoute.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,gBAAgB;EAChB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,WAAW;EACX,iBAAiB;EACjB,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;EAChB,WAAW;EACX,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,2BAA2B;AAC7B;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".auth-form-container {\n  height: 100vh;\n  display: flex;\n}\n\n.auth-form {\n  margin: auto;\n  padding: 20px;\n  /* background: #f1f1f1; */\n  border-radius: 10px;\n  max-width: 400px;\n  width: 100%;\n  text-align: center;\n}\n\n.auth-form .company {\n  --size: 130px;\n  margin: 0 auto;\n}\n\n.auth-form input {\n  padding: 6px 20px;\n  width: 100%;\n  padding: 7px 20px;\n  border-radius: 6px;\n  border: 2px solid #111;\n}\n\n.auth-form button {\n  display: block;\n  width: 100%;\n  margin-top: 20px;\n  border: none;\n  background: #111;\n  color: #fff;\n  font-weight: 700;\n  padding: 7px 20px;\n  border-radius: 6px;\n  cursor: pointer;\n  transition: background 0.3s;\n}\n\n.auth-form button:hover {\n  background: #333;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
