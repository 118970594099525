import React, { useEffect, useState } from "react";
import Circle from "../Cirlcle/Circle";

function Spiral({ employees }) {
  const [rerender, setRerender] = useState(0);

  useEffect(() => {
    if (employees.length > 8) {
      setRerender((prev) => prev + 1);
    }
  }, [employees.length]);

  const chunkSize = Math.ceil(employees.length / 5);
  const chunks = Array.from({ length: 5 }, (v, i) =>
    employees.slice(i * chunkSize, i * chunkSize + chunkSize)
  );

  return (
    <>
      {chunks.map(
        (chunk, index) =>
          chunk.length > 0 && (
            <Circle
              key={`${index}-${chunk.length}-${rerender}`}
              employees={chunk}
              index={index}
            />
          )
      )}
    </>
  );
}

export default Spiral;
