import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import axios from "axios";

import MainBoard from "./pages/MainBoard";
import Admin from "./pages/Admin";
import EmployeeStats from "./pages/EmployeeStats";
import Administration from "./pages/Administration";
import ProtectedRoute from "./layout/ProtectedRoute/ProtectedRoute";
// import CameraStream from "./pages/CameraStream";

async function fetchEmployees() {
  try {
    console.log("fetching...");
    const response = await axios.get("/api/employees/filtered");
    return response.data;
  } catch (error) {
    console.error("Error fetching data: ", error);
    return [];
  }
}

async function fetchGuests() {
  try {
    const response = await axios.get("/api/guests/");
    return response.data;
  } catch (error) {
    console.error("Error fetching data: ", error);
    return [];
  }
}

async function fetchGarages() {
  try {
    const response = await axios.get("/api/garages/");
    return response.data;
  } catch (error) {
    console.error("Error fetching data: ", error);
    return [];
  }
}

function App() {
  const [employees, setEmployees] = useState([]);
  const [garages, setGarages] = useState([]);
  const [guests, setGuests] = useState([]);

  async function updateEmployees() {
    const updatedEmployees = await fetchEmployees();
    setEmployees(updatedEmployees);
  }

  async function updateGarages() {
    const updatedGarages = await fetchGarages();
    setGarages(updatedGarages);
  }

  async function updateGuests() {
    const updatedGuests = await fetchGuests();
    setGuests(updatedGuests);
  }

  // Fetch employeesList on initial mount
  useEffect(() => {
    fetchEmployees().then((data) => setEmployees(data));
  }, []);

  // Fetch garagesList on initial mount
  useEffect(() => {
    fetchGarages().then((data) => setGarages(data));
  }, []);

  // Fetch guests on initial mount
  useEffect(() => {
    fetchGuests().then((data) => setGuests(data));
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <MainBoard
              employees={employees}
              updateEmployees={updateEmployees}
              garages={garages}
              updateGarages={updateGarages}
              guests={guests}
              updateGuests={updateGuests}
            />
          }
        />
        <Route element={<ProtectedRoute />}>
          <Route path="/admin" element={<Admin employees={employees} />} />
          <Route path="/administration" element={<Administration />} />
        </Route>
        <Route path="*" element={<h1>404 Not Found</h1>} />
        <Route
          path="/stats/:index"
          element={<EmployeeStats employees={employees} />}
        />
        {/* <Route path="/kicavicalon25" element={<CameraStream />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
