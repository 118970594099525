import React, { useEffect, useState } from "react";
import MenageEmployee from "../components/MenageEmployee/MenageEmployee";
import axios from "axios";

async function fetchEmployeesUnfiltered() {
  try {
    console.log("fetching...");
    const response = await axios.get("/api/employees");
    return response.data;
  } catch (error) {
    console.error("Error fetching data: ", error);
    return [];
  }
}

function Administration() {
  const [employees, setEmployees] = useState([]);

  async function updateEmployees() {
    const updatedEmployees = await fetchEmployeesUnfiltered();
    setEmployees(updatedEmployees);
  }

  // Fetch employeesList on initial mount
  useEffect(() => {
    fetchEmployeesUnfiltered().then((data) => setEmployees(data));
  }, []);

  return (
    <ul className="employees-holder">
      {employees.map((employee) => (
        <MenageEmployee key={employee._id} employee={employee} />
      ))}
    </ul>
  );
}

export default Administration;
