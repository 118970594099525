import React, { useState, useEffect } from "react";
import { MdOutlineBedtime, MdAccessTime } from "react-icons/md";
import { LiaTimesSolid } from "react-icons/lia";

function EmployeeCard({
  employee,
  index,
  togglePresence,
  toggleSleep,
  onMouseDown,
  onMouseUp,
  totalTime,
}) {
  const [openMenu, setOpenMenu] = useState(false);
  const [screenX, setScreenX] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenX(window.innerWidth);

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function formatTime(totalMinutes) {
    const totalHours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;

    let formattedTime = "";
    if (totalHours > 0) {
      formattedTime += `${totalHours}h `;
    }
    if (remainingMinutes > 0 || totalHours === 0) {
      formattedTime += `${remainingMinutes}m`;
    }

    return formattedTime;
  }
  return (
    <div
      onClick={() => setOpenMenu(true)}
      className={`employee-card 
  ${!employee.present && !employee.sleep ? "inactive" : ""} 
  ${employee.sleep ? "sleeper" : ""}
  ${employee.remote ? "remote" : ""}`.trim()}
    >
      {screenX < 400 && openMenu ? null : (
        <div className="num">{index + 1}.</div>
      )}
      <div className="img-holder">
        <div
          className={`circle ${employee.present ? "active" : ""} ${
            employee.sleep ? "sleeping" : ""
          }`}
        ></div>
        <img src={employee.img} alt="" />
      </div>
      {screenX < 500 && openMenu ? null : (
        <div className="info">
          <div className="name">{employee.name}</div>
          <div className="title">{employee.title}</div>
        </div>
      )}
      <div className="other">
        {openMenu ? (
          <div className="menu">
            <div
              className="item"
              onClick={(event) => {
                event.stopPropagation();
                setOpenMenu(false);
              }}
            >
              <LiaTimesSolid />
            </div>
            <div
              className="item"
              onClick={(event) => {
                event.stopPropagation();
                togglePresence();
                setOpenMenu(false);
              }}
            >
              <MdAccessTime />
            </div>
            {!employee.present && (
              <div
                className="item"
                onClick={(event) => {
                  event.stopPropagation();
                  toggleSleep();
                  setOpenMenu(false);
                }}
              >
                <MdOutlineBedtime />
              </div>
            )}
          </div>
        ) : (
          <>
            <div className="total-time">{formatTime(totalTime)}</div>
            {index + 1 === 1 && (
              <img
                className="medal"
                src="/media/medals/gold.svg"
                alt="gold medal"
              />
            )}
            {index + 1 === 2 && (
              <img
                className="medal"
                src="/media/medals/silver.svg"
                alt="silver medal"
              />
            )}
            {index + 1 === 3 && (
              <img
                className="medal"
                src="/media/medals/bronze.svg"
                alt="bronze medal"
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default EmployeeCard;
