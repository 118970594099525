import "./menageEmployee.css";

import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaPlus, FaMinus } from "react-icons/fa6";

function MenageEmployee({ employee }) {
  const [minutes, setMinutes] = useState(60);
  const [dayDate, setDayDate] = useState("");
  const [workedTime, setWorkedTime] = useState(0);

  useEffect(() => {
    handleDateInput(employee.workHistory[employee.workHistory.length - 1].date);
  }, []);

  async function updateTimeRecord(minutes) {
    try {
      const response = await axios.put("api/employees/updateTimeRecord", {
        id: employee._id,
        day: dayDate,
        minutes: minutes,
      });

      if (response.status === 200) {
        console.log(
          response.data.workHistory.find((day) => day.date === dayDate)
            .totalTimeInDay
        );
        setWorkedTime(
          response.data.workHistory.find((day) => day.date === dayDate)
            .totalTimeInDay
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  const timeValues = [10, 30, 60, 120, 240];

  function convertMinutesToTime(minutes) {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return hours === 0
      ? `${mins}m`
      : mins === 0
      ? `${hours}h`
      : `${hours}h ${mins}m`;
  }

  function handleDateInput(value) {
    console.log(
      employee.name,
      value,
      employee.workHistory.find((day) => day.date === value).totalTimeInDay
    );
    setDayDate(value);
    setWorkedTime(
      employee.workHistory.find((day) => day.date === value).totalTimeInDay
    );
  }

  return (
    <li>
      <div className="menage-employee">
        <div className="header">
          <div className="button-holder decrees">
            <button onClick={() => updateTimeRecord(minutes * -1)}>
              <FaMinus />
            </button>
            <span>Decrees</span>
          </div>
          <div className="img-holder">
            <img src={employee.img} alt="cubunabaazilaaka"></img>
          </div>
          <div className="button-holder increase">
            <button onClick={() => updateTimeRecord(minutes)}>
              <FaPlus />
            </button>
            <span>Increase</span>
          </div>
        </div>
        <div className="body">
          <div className="info">
            <h3>{employee.title}</h3>
            <h2>{employee.name}</h2>
          </div>
          <div className="date-holder">
            <span>Date:</span>
            <select
              className="date"
              onChange={(e) => handleDateInput(e.target.value)}
              value={dayDate}
            >
              {employee.workHistory.slice(-20).map((day) => (
                <option key={day._id} value={day.date}>
                  {day.date}
                </option>
              ))}
            </select>
            <div className="time">{convertMinutesToTime(workedTime)}</div>
          </div>
          <div className="values-holder">
            {timeValues.map((value) => (
              <div
                key={value}
                className={`value ${value === minutes ? "active" : ""}`}
                onClick={() => {
                  setMinutes(value);
                  console.log(minutes);
                }}
              >
                {convertMinutesToTime(value)}
              </div>
            ))}
          </div>
        </div>
      </div>
    </li>
  );
}

export default MenageEmployee;
