import React from "react";
import Spiral from "../Spiral/Spiral";

function EmployeesList({ list }) {
  const relevantEmployees = list.filter(
    (employee) => employee.present || employee.sleep
  );

  return (
    <ul className="company">
      <img className="logo-min" src="/logo.svg" alt="logo" />
      <Spiral employees={relevantEmployees} />
    </ul>
  );
}

export default EmployeesList;
