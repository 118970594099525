import React, { useState } from "react";
import axios from "axios";

function AddEmployeeForm({ employees }) {
  const [addUserPopup, setAddUserPopup] = useState(false);
  const [activeEmployee, setActiveEmployee] = useState(null);

  const [name, setName] = useState("");
  const [img, setImg] = useState("");
  const [title, setTitle] = useState("");
  const [code, setCode] = useState("");

  const [updateName, setUpdateName] = useState("");
  const [updateImg, setUpdateImg] = useState("");
  const [updateTitle, setUpdateTitle] = useState("");
  const [updateCode, setUpdateCode] = useState("");

  const handleSubmitOfUpdate = async (e) => {
    e.preventDefault();

    try {
      const updatedEmployeeData = {
        name: updateName,
        title: updateTitle,
        img: updateImg,
        code: updateCode,
      };

      const response = await axios.put(
        `/api/employees/update/${employees[activeEmployee]._id}/`,
        updatedEmployeeData
      );

      console.log("Employee updated:", response.data);
    } catch (error) {
      console.error("Error updating employee:", error.response.data);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const employeeData = {
        name,
        title,
        code,
      };

      if (img) {
        employeeData.img = img;
      }

      const response = await axios.post("/api/employees", employeeData);

      console.log("Employee added:", response.data);
      // Reset form fields after successful submission
      setName("");
      setImg("");
      setTitle("");
      setCode("");
    } catch (error) {
      console.error("Error adding employee:", error.response.data);
    }
  };

  const handleDelete = async () => {
    try {
      if (activeEmployee === null) {
        console.error("No employee selected for deletion.");
        return;
      }

      const response = await axios.delete(
        `/api/employees/${employees[activeEmployee]._id}`
      );

      if (response.status === 200) {
        console.log("Employee deleted:", response.data);
        // Reset form fields and active employee after successful deletion
        setName("");
        setImg("");
        setTitle("");
        setCode("");
        setActiveEmployee(null);
      }
    } catch (error) {
      console.error("Error deleting employee:", error.response.data);
    }
  };

  const handleEmployeeClick = (employeeId) => {
    setActiveEmployee(employeeId);

    // Reset form fields to display the selected employee's details
    if (employeeId !== null) {
      setUpdateName(employees[employeeId]?.name);
      setUpdateImg(employees[employeeId]?.img);
      setUpdateTitle(employees[employeeId]?.title);
      setUpdateCode(employees[employeeId]?.code);
    }
  };

  return (
    <>
      <div className="admin-grid">
        <div className="sidebar">
          <div className="header">
            <h2>Employees</h2>
            <button
              onClick={() => {
                setAddUserPopup(!addUserPopup);
              }}
            >
              {" "}
              + Add Employee
            </button>
          </div>
          <ul>
            {employees.map((em, index) => (
              <li
                className={activeEmployee === index ? "active" : ""}
                onClick={() => handleEmployeeClick(index)}
                key={index}
              >
                <img src={em.img} alt={em.name}></img>
                <div className="name">{em.name}</div>
              </li>
            ))}
          </ul>
        </div>
        <div className="user-info">
          <div>
            {activeEmployee !== null ? (
              <>
                <form onSubmit={handleSubmitOfUpdate}>
                  <label>Image URL:</label>
                  <input
                    type="text"
                    value={updateImg}
                    onChange={(e) => setUpdateImg(e.target.value)}
                  />
                  <label>Name:</label>
                  <input
                    type="text"
                    value={updateName}
                    onChange={(e) => setUpdateName(e.target.value)}
                    required
                  />
                  <label>Title:</label>
                  <input
                    type="text"
                    value={updateTitle}
                    onChange={(e) => setUpdateTitle(e.target.value)}
                  />
                  <label>Code:</label>
                  <input
                    type="text"
                    value={updateCode}
                    onChange={(e) => setUpdateCode(e.target.value)}
                    required
                  />
                  <button type="submit">Update Employee</button>
                </form>
                <button onClick={handleDelete}>Delete</button>
              </>
            ) : (
              <div>Select an employee</div>
            )}
          </div>
        </div>
      </div>
      {addUserPopup && (
        <div className="add-user-popup">
          <h2>Add Employee</h2>
          <form onSubmit={handleSubmit}>
            <label>Name:</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <label>Image URL:</label>
            <input
              type="text"
              value={img}
              onChange={(e) => setImg(e.target.value)}
            />
            <label>Title:</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <label>Code:</label>
            <input
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              required
            />
            <button type="submit">Add Employee</button>
          </form>
        </div>
      )}
    </>
  );
}

export default AddEmployeeForm;
