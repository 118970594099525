import React, { useEffect, useState } from "react";
import EmployeeCard from "../components/EmployeeCard/EmployeeCard";
import { useParams } from "react-router-dom";

function EmployeeStats({ employees }) {
  const { index } = useParams();
  const employee = employees?.at(index);

  if (!employee) {
    return <div>No employee found</div>;
  }

  console.log(employee);

  const lastWeekValue = employee.week;

  return (
    <EmployeeCard
      index={parseInt(index)}
      onDoubleClick={() => {}}
      employee={employee}
      totalTime={lastWeekValue}
    />
  );
}

export default EmployeeStats;
